.languageDropdownWrapper {
    :global(.bx--dropdown) {
        background-color: rgb(16, 6, 146);
        border: 2px solid #f6f6f6;
        border-radius: 5px;
        & :global(.bx--list-box__label){
            color: #f6f6f6;
            font-weight: 700;
        }
        & :global(.bx--list-box__menu-icon){
            color: #f6f6f6;
            font-weight: 700;
        }
        & :global(svg) {
            fill: #f6f6f6;
            stroke-width: 3;
            height: 3rem;
        }

    }
}