.loginBtn {
    align-self: center;
    & :global(.bx--btn) {
        font-weight: 700;
        min-height: 1rem;
        border-radius: 20px;
        height: 2rem;
        color: black;
        background-color: #f6f6f6;
    }
    margin-right: 1rem;
}

.headerNameWrapper {
    :global(.bx--header__nav::before) {
        background-color: white;
        height: 5rem;
    }
}