@import "https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:400,600&display=swap";
@import "carbon-components/scss/globals/scss/styles.scss";
@import "carbon-components/scss/globals/scss/_vars.scss";
@import "@ibm/plex/css/ibm-plex.min.css";
@import "@carbon/grid/scss/grid";
@import '@carbon/themes/scss/themes';
@import "@carbon/charts/styles.css";

$carbon--theme: $carbon--theme--g10;
* {
  font-family: "IBM Plex Sans";
}
body {
  margin: 0;
  background-color: #f4f4f4;
}

.cardStyle {
  border: 0.3px solid grey;
  width: 450px;
}

.prflimg {
  //background-image: url("/blank.png");
  width: 7rem;
  height: 7 rem;
}

.prov-info {
  padding: 1rem;
}

.scndBtn {
  margin-left: auto;
}

// .headerDiv {
//   margin-left: 9rem;
// }

.cardsDiv {
  margin-left: 2rem;
}

.badgeBox {
  width: min-content;
  padding: 1rem;
}

.allBadges {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  flex-wrap: wrap;
  text-align: center;
}
@media screen and (min-width: 1030px) {
  .headerDiv {
    margin-left: 10rem;
  }
  .cardsDiv {
    display: flex;
    width: 75%;
    margin-left: 5rem;
  }
  .allBadges {
    align-items: flex-start;
    justify-content: space-around;
  }
}
@media screen and (max-width: 300px) {
  .badgeBox {
    padding: 0.5rem;
  }
  img {
    width: 32px;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
  .allBadges {
    justify-content: space-evenly;
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .badgeBox {
    padding: 0.5rem;
  }

  img {
    width: 45px;
  }
  .badgeText {
    font-size: 0.8em;
    padding-left: 0.4em;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .badgeBox {
    padding: 0.5rem;
  }

  img {
    width: 35px;
  }
  .badgeText {
    font-size: 0.7em;
    padding-left: 0.4em;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .badgeBox {
    padding: 0.5rem;
  }

  img {
    width: 35px;
  }
  .badgeText {
    font-size: 0.7em;
    padding-left: 0.4em;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 800px) and (min-width: 700px) {
  .badgeBox {
    padding: 0.5rem;
  }

  img {
    width: 40px;
  }
  .badgeText {
    font-size: 0.8em;
    padding-left: 0.4em;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 1rem;
  }
}
@media screen and (max-width: 1030px) and (min-width: 950px) {
  .badgeBox {
    padding: 0.5rem;
    width: min-content;
  }

  img {
    width: 60px;
  }
  .badgeText {
    font-size: 1.2em;
    padding-left: 0.4em;
  }
  .bx--content-switcher {
    width: 78%;
  }
  .cardsDiv {
    margin-right: 2rem;
    margin-left: 0;
  }
  .headerDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 1rem;
    margin-right: 15rem;
  }
}

@media screen and (max-width: 670px) {
  .cardStyle {
    width: 300px;
  }
  .prov-info {
    padding: 0.5rem;
  }
  .prflimg {
    width: 6rem;
    height: 6rem;
  }
  .scndBtn {
    margin-left: 0px;
  }
}

@media screen and (max-width: 310px) {
  .cardStyle {
    width: 270px;
  }
  .prov-info {
    padding: 0.5rem;
    margin-right: inherit;
  }
  .prflimg {
    width: 5rem;
    height: 5rem;
  }
  .scndBtn {
    margin-left: 0px;
  }
}

.tablets {
  width: 50%;
}

// .bx--header__name {
//   width: 16rem;
// }
.bx--header {
  // background-color: #072665;
  background-color: #161616;
  margin-bottom: 0;
  @media print {
    z-index: -8000;
  }
}

.bx--header ~ .bx--content {
  margin-top: 3rem;
}

// @media (min-width: 62rem) {
.bx--content {
  width: 100vw;
  height: calc(100vh - 3rem);
  // height:100vh;
  overflow: auto;
  background-color: #f4f4f4;
  padding: 0;
}
// }

.mapbox {
  height: calc(100vh - 3rem);
  margin-top: 3rem;
}
.bx--text-input:focus,
.bx--text-input:active {
  outline: none;
  outline-offset: none;
}
.dALCef {
  padding-left: 0rem !important;
}
#printable {
  display: none;
}
.slide img {
  object-fit: cover;
  image-rendering: auto;
}

@media screen and (min-width: 1001px) {
  .contentalt {
    width: 60%;
    height: 60%;
    align-self: center;
    margin-top: 13rem;
    padding: 2em;
    background-color: #000000b8;
  }
}
@media screen and (max-width: 1000px) {
  .altrnt {
    background-color: #000000b8;
    position: absolute;
    width: 100%;
    height: 100vh;
  }
}

.footerDiv {
  height: 47px;
  background-color: black;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

@media print {
  #printable {
    display: inline;
    height: auto;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9000;
    overflow: visible;
  }

  #listDiv {
    display: inline;
    page-break-after: always;
  }

  .bx--side-nav {
    display: none;
  }

  .bx--header {
    display: none;
  }

  .WatsonAssistantChatHost {
    display: none;
    z-index: -9999;
  }
}
// .user-nav-button {
//   display: inline-block;
// }
// .mobile-nav-button {
//   display: none;
// }
@media only screen and (max-width: 699px) {
  #root > div {
    width: 100%;
    height: 100%;
  }
  .user-nav-button {
    display: none;
  }
  .ziptext {
    font-size: 0.75rem;
    letter-spacing: 0.32px;
    display: inline;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: bold;
    line-height: 1rem;
    vertical-align: baseline;
    padding: 1.5rem;
  }
  .bx--header ~ .bx--content {
    // height: calc(100% - 3rem);
    height: 100%;
    padding: 0;
    // padding-bottom: 3rem;
    margin: 3rem 0 0 0;
  }
  #root > div {
    display: flex;
    flex-direction: column;
  }
  .mapbox {
    height: 100%;
    margin: 0 !important;
  }
  #side-bar {
    display: none;
  }
  //   #bottom-nav {
  //     width: 100%;
  //   }
  #WACWidget .WACWidget .WACWidget--launched {
    max-height: 100% !important;
  }

  //   .mobile-nav-button {
  //     margin: 0 1em;
  //     text-decoration: none;
  //     display: inline-block;
  //     width: 20%;

  a:link {
    text-decoration: none;
  }
}

@media screen and (max-width: 550px) and (min-width: 416px) {
  .qna {
    overflow: auto;
    align-items: center;
  }
}

@media screen and (max-width: 550px) and (min-width: 416px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  // .bx--label{
  //   align-self: center;
  // }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 416px) {
  // .buttonContainer {
  //   margin-left: 25%;
  //   margin-right: 25%;
  //   margin-top: 5%;
  //   margin-bottom: 2%;
  // }
  .bx--text-input {
    margin: auto;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  // .bx--btn-set {
  //   margin-left: 0;
  // }
}

@media (max-width: 350px) {
  // .bx--label {
  //   align-self: center;
  // }

  .bx--slider-container {
    margin-left: 2rem;
    width: 300px;
    display: flex;

    overflow: auto;
  }

  .bx--slider {
    min-width: auto;
    max-width: 15rem;
    margin: auto;
  }

  .bx--form-item {
    width: 270px;
    align-items: center;
  }

  // .bx--btn-set {
  //   justify-content: center;
  // }
}

@media (max-width: 1540px) and (min-width: 1000px) {
  .bx--slider {
    max-width: 30rem;
  }
  // .bx--btn-set {
  //   margin-left: 2rem;
  //   justify-content: space-evenly;
  // }
}

@media (max-width: 300px) {
  // .bx--label {
  //   align-self: center;
  // }

  .bx--slider-container {
    margin-left: 1.5rem;
    width: 270px;
    display: flex;

    overflow: auto;
  }

  .bx--slider {
    min-width: auto;
    max-width: 15rem;
    margin: auto;
  }

  .bx--form-item {
    width: 250px;
    align-items: center;
  }

  // .bx--btn-set {
  //   justify-content: flex-end;
  // }
}

@media (max-width: 800px) and (min-width: 699px) {
  // .bx--btn-set {
  //   margin-left: 2rem;
  //   justify-content: flex-start;
  // }
  .hnp {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 1000px) and (min-width: 900px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  // .bx--label{
  //   align-self: center;
  // }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 699px) and (min-width: 550px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  // .bx--label{
  //   align-self: center;
  // }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 470px) and (min-width: 417px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  // .bx--label{
  //   align-self: center;
  // }
  .bx--slider {
    max-width: 15rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 1055px) {
  .WACLauncher__ButtonContainer {
    display: none !important;
  }
}

// Fixing progress steps truncation caused by carbon
.bx--progress--space-equal .bx--progress-label {
  margin-right: 0;
  padding-right: 0;
}

// Screen Reader Only - for visually hidden elements
.srOnly {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0,0,0,0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.bx--number input[type="number"]:disabled, .bx--text-input:disabled {
  color: #8c8c8c; // $disabled-03
  -webkit-text-fill-color: #8c8c8c;
}

.bx--label--disabled, .bx--label{
  color: #171717;
  -webkit-text-fill-color: #171717;
}