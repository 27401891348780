.pageContainer {
  height: 80vh;
  width: 100vw;
  background-color: #f4f4f4;
  z-index: 1;
  margin: 3rem 0;
  @media screen and (max-width: 768px) {
    height: 100vh;
    margin: -1rem 0;
    background-color: white;
  }
}

.contentContainer {
  grid-area: "content";
  margin: auto;
  height: 100%;
  width: 100%;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 768px) {
    height: 100vh;
    margin: 0;
  }
}


.innerContent {
  height: 100%;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
  padding-top: 4%;
  @media screen and (max-width: 800px) {
    padding-left: 1%;
    padding-right: 1%;
  }
}

.textWrapper {
  margin-top: 2rem;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
    line-height: 1.3;
  }
  @media screen and (min-height: 880px) and (min-width: 1600px) {
    p {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.parentContainer {
  height: 100vh;
  @media (min-width: 800px) {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.page {
  min-height: 100vh;
  align-self: center;
  padding: 2rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  @media (min-width: 800px) {
    min-height: 50vh;
    margin: 1rem;
    width: 800px;
    border: 1px solid grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

.content {
  // min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header0 {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

.textInputTitle {
  font-size: 1rem;
  font-weight: 600;
  margin: 2rem 0 2rem 0;
}

.paragraph {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  line-height: 1.25rem;
  @media (min-width: 800px) {
    width: 640px !important;
  }
}

.textInput {
  @media (min-width: 800px) {
    width: 300px !important;
  }
}

.image {
  width: 80vw !important;
  @media (min-width: 800px) {
    width: 640px !important;
  }
}

.bottom {
  display: flex;
  justify-content: center;
  @media (min-width: 800px) {
    // width: 480px !important;
    justify-content: left;
  }
}

.buttonGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 300px;
  @media (min-width: 800px) {
    flex-wrap: nowrap;
    width: auto;
  }
}

.buttonGroupWide {
  display: flex;
  justify-content: center;

}

.textButtonGroup {
  display: flex;
  justify-content: space-between;
  @media (min-width: 800px) {
    width: 480px !important;
  }
}

.textButtonGroupRight {
  display: flex;
  justify-content: right;
}

.buttonTertiary {
  color: black;
  border-color: grey;
  border-radius: 3px;
  margin: 1rem;
  padding: calc(0.875rem - 3px) 15px calc(0.875rem - 3px) 15px;
  :hover {
    background-color: black !important;
  }
  :active {
    background-color: black !important;
  }
  :focus {
    background-color: black !important;
  }
}

.buttonPrimary {
  background-color: black !important;
  border-color: black;
  border-radius: 3px;
  margin: 1rem;
  padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
  :hover {
    background-color: black !important;
  }
  :active {
    background-color: black !important;
  }
  :focus {
    background-color: black !important;
  }
  .bx--btn--primary {
    background-color: black;
  }
  .bx--btn--primary:hover:enabled {
    background-color: black !important;
  }

  .bx--btn--primary {
    background-color: black;
  }
}

.textButton {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.textButtonContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin: 1.5rem 0;
  cursor: pointer;
}


.progressBarFilled {
  height: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  position: absolute;
  // top: -1rem;
  bottom: 2rem;
  background-color: black;
  .first {
    width: 16vw;
    @media (min-width: 800px) {
      width: 128px;
    }
  }
  .second {
    width: 32vw;
    @media (min-width: 800px) {
      width: 256px;
    }
  }
  .third {
    width: 48vw;
    @media (min-width: 800px) {
      width: 384px;
    }
  }
  .fourth {
    width: 64vw;
    @media (min-width: 800px) {
      width: 512px;
    }
  }
  .fifth {
    width: 80vw;
    @media (min-width: 800px) {
      width: 640px;
    }
  }
}

.progressBarEmpty {
  height: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  position: absolute;
  bottom: 2rem;
  .empty {
    width: 80vw;
    background-color: white;
  }
  @media (min-width: 800px) {
    width: 640px;
  }
}

.video {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
  @media (min-width: 800px) {
    height: 16rem;
    width: 30rem;
  }
}

.progressBarContainer {
  position: relative;
}
